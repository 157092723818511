<template>
  <portal to="dialog" :disabled="!portal" @mousemove="$store.state.avalon.mouseX = $event.clientX; $store.state.avalon.mouseY = $event.clientY;">
  <v-fade-transition>
    <v-sheet
        v-if="display"
        @click.stop="overlayClick"
        :color="overlay"
        style="position: fixed; top: 0; bottom: 0; left: 0;right: 0; z-index: 999999999999999 ;"
        :style="overlayStyle"
        class="d-flex align-center "
        :class="[
            { 'justify-center' : !fullscreenAlign },
            { 'justify-end'    : fullscreenAlign === 'right' },
            { 'justify-start'  : fullscreenAlign === 'left' },
            { 'align-center'   : verticalAlign === 'center' },
            { 'align-baseline' : verticalAlign === 'top' },
            { 'align-end'      : verticalAlign === 'bottom' }

        ]"
    >
      <v-sheet
          light
          @click.stop
          @mousedown="mouseDown"
          @mouseup="mouseUp"
          :color="backgroundColor"
          :width="dialogWidth"
          :class="[
              {'fill-height '  :   fullscreen || SM || fillHeight  },
              {'wsRoundedHalf' : !(fullscreen || SM) },
          ]"
          :style="`max-height : ${maxHeight || '90%'}; ${height ? `height : ${height};` : ''} margin-right : ${marginRight || 0}; ${borderRadius ? `border-radius : ${borderRadius}px !important` : ''}`"

          class="justify-space-between overflow-hidden d-flex flex-column"
          elevation="5"


      >

        <!-- HEADER-->
        <!-- Title and Close Button-->
        <slot name="header">

          <div v-if="!blank"
               class="d-flex justify-space-between align-center pb-3 px-6 pt-5" >
            <h3 :style="`color : ${wsDARKER}; font-weight: 600; font-size: ${!SM ? 20 : 16}px`">
              <v-icon v-if="!!titleIcon" :color="wsACCENT">{{ titleIcon }}</v-icon>
              {{  title  }}
            </h3>
            <v-btn
                @click="display = false"
                class="mr-n2"
                :color="wsDARKER"
                icon
            >
              <v-icon >mdi-close</v-icon>
            </v-btn>
          </div>
          <slot name="header-after"></slot>
        </slot>


        <!-- CONTENT-->
        <div style=" position: relative"
             :style="notCentered ? 'height: 100%;' :  'min-height: 35px;' "
             class="overflow-y-auto "
             ref="dialogContent"
             :class="[{'px-6' : !noPadding} , {'pb-8' : !noPadding}]"
        >
          <!-- Subtitle-->
          <h3 v-if="subtitle"
              :style="`font-weight: 400; color : ${wsDARKER};
              font-size: ${!SM ? 18 : 14}px;
              ${!SM ? 'max-width : 85%' : ''}`"
              class="mb-5"
          >
            {{  subtitle  }}
          </h3>
          <!-- Content-->
          <slot v-if="!displayConfirmDelete" name="default"></slot>
          <template v-else>
            <slot name="confirm-delete">
              <v-sheet  class="fill-height">

                <h4 class="text-center font-weight-medium mt-6">{{ deleteConfirmationText }}</h4>
                <h5 v-if="confirmDeleteCode" class="text-center mt-3  font-weight-medium">{{ $t('DeleteConfirmationCodeDescription') }}:</h5>
                <h4 v-if="confirmDeleteCode" class="text-center mt-1 mb-5">{{ confirmDeleteCode }}</h4>
                <ws-text-field
                    v-if="confirmDeleteCode"
                    v-model="deleteConfirmationCode"
                    :placeholder="$t('EnterConfirmationCode')"
                    clearable
                    :error="!!deleteCodeError"
                    :error-messages="deleteCodeError"
                    @input="deleteCodeError = null"
                    :hide-details="false"
                />
              </v-sheet>
            </slot>
          </template>

        </div>


        <!-- BUTTONS -->
        <slot name="footer">
          <v-sheet v-if="!noNavigation" color="transparent" style="position:relative;" class="pt-3 pa-6" >
            <!-- Transparent Overlay for Overflow -->
            <slot name="footer.content"></slot>
            <!-- Buttons -->
            <div v-if="!displayConfirmDelete && !noNavigation" class="d-flex align-center " :class="showDelete ? 'justify-space-between' : 'justify-end'" >
              <slot name="delete">
                <v-btn v-if="showDelete"  @click="displayConfirmDelete=true" text  class=" noCaps"   :color="wsACCENT"  >
                  <v-icon  class="mr-1">mdi-delete-outline</v-icon>
                  {{ $t('Delete') }}
                </v-btn>
              </slot>
              <div v-if="!hideButtons"  class=" d-flex justify-end">
                <!-- Cancel button  -->
                <slot name="buttons">
                  <ws-button
                      @click="cancelButtonAction"
                      :label="cancelText"
                      outlined
                      :disabled="loading"
                      class="mr-2"
                  />
                  <ws-button
                      v-if="!hideSave"
                      @click="$emit('save')"
                      :label-raw="saveText"
                      :loading="loading"
                      :disabled="disableSave"
                  />
                </slot>

              </div>
            </div>
            <div v-else-if="!noNavigation"  >
              <div  class="d-flex justify-end">
                <v-btn height="40"   @click="!closeDelete ? displayConfirmDelete = false : display = false" :disabled="loading" class=" noCaps" dark  :color="wsDARKER" >{{ $t('Cancel') }}</v-btn>
                <v-btn   @click="deleteAction"  :loading="loading" class=" noCaps" text >{{ $t(deleteText) }}</v-btn>
              </div>
            </div>
          </v-sheet>
          <v-sheet color="transparent" height="1px" v-else />
        </slot>




      </v-sheet>

    </v-sheet>
  </v-fade-transition>
  </portal>


</template>

<script>


import {mapState} from "vuex";

export default {
  name: "wsDialog",
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    overlayColor : {
      type : String
    },
    noPadding : {
      type : Boolean,
      default : false
    },
    fullscreen : {
      type : Boolean,
      default : false
    },
    blank : {
      type : Boolean,
      default : false
    },
    hideButtons : {
      type : Boolean,
      default : false,
    },
    loading : {
      type : Boolean,
      default : false,
    },
    title : {
      type : String,
      default : '',
    },
    subtitle : {
      type : String,
      default : '',
    },
    saveText : {
      type : String,
      default : 'Зберегти',
    },
    cancelText : {
      type : String,
      default : 'Cancel',
    },
    showSave : {
      type : Boolean,
      default : true,
    },
    showDelete : {
      type : Boolean,
      default : false,
    },
    cancelAction : {
      type : Boolean,
      default : false,
    },
    width : {
      type : String,
      default : '600',
    },
    noNavigation : {
      type : Boolean,
      default : false,
    },
    displayConfirmDelete : {
      type : Boolean,
      default : false,
    },
    closeDelete : {
      type : Boolean,
      default : false,
    },
    confirmDeleteText: {
      type : String,
      default : null,
    },
    confirmDeleteCode: {
      type : String,
      default : null,
    },
    persistent : {
      type : Boolean,
      default : false,
    },
    fullscreenWidth : {
      type : String
    },
    fullscreenAlign : {
      type : String
    },
    verticalAlign : {
      type : String,
      default : 'center'
    },
    notCentered : {
      type : Boolean,
      default : false,
    },
    titleIcon : {
      type : String
    },
    portal : {
      type : Boolean,
      default : false,
    },
    marginRight : {
      type : String
    },
    marginBottom : {
      type : String
    },
    marginTop : {
      type : String
    },
    fillHeight : {
      type : Boolean,
      default : false,
    },
    borderRadius : {
      type : String
    },
    disableSave : {
      type : Boolean,
      default : false,
    },
    backgroundColor : {
      type : String,
      default : '#FFFFFF'
    },
    deselectBlindZoneX : {
      type : Number
    },
    deleteText : {
      type : String,
      default : "Delete"
    },
    hideSave : {
      type : Boolean,
      default : false
    },
    maxHeight : {
      type : String
    },
    height : {
      type : String
    },
    hideClose : {
      type : Boolean,
      default : false,
    },
    allowOverlayPointerEvent : {
      type : Boolean,
      default : false,
    },
    avalonSidebar : {
      type : Boolean,
      default : false
    }
  },

  data() {
    return {
      display : false,
      deleteConfirmationText : '',
      deleteConfirmationCode : null,
      deleteCodeError : null,
      overlay : '',
      isMouseDown : false,
      mouseX : 999990,
      mouseY : 999990,
    }
  },
  computed : {
    ...mapState('avalon' , ['mouseClickX']),

    overlayStyle() {
      let style = `padding-top : ${this.marginTop || 0}; padding-bottom : ${this.marginBottom || 0};`

      if ( this.deselectBlindZoneX && this.$store.state.avalon.mouseX < this.deselectBlindZoneX ) {
        style += 'pointer-events : none;'
      }

      return style

    },
    dialogWidth() {
      if ( this.fullscreen && this.fullscreenWidth ) {
        return this.fullscreenWidth
      }
      return !(this.fullscreen || this.SM)  ? this.width : '100%'
    },
    getTotalClass() {
      if ( this.displayShow(['sm'])) {
        return 'fill-height '
      }
      return !this.blank ? 'px-6' : 'pa-1'
    }
  },
  watch : {
    mouseClickX(value) {
      if ( !this.avalonSidebar) {
        return
      }
      if ( value <  this.WINDOW_WIDTH - 345 ) {
        this.display = false
      }
    },
    value(val) {
      if ( this.value !== this.display) {
        this.display = val
      }
    },
    display(val) {
      if ( !val ) {
        this.displayConfirmDelete = false
      }
      if (this.value !== this.display) {
        this.$emit('input',val)
      }
      if ( val === false ) {  this.$emit('close') }
    },
    SM(value) {
      if ( this.portal ) {
        this.$store.state.MOBILE_FULLSCREEN = value
      }
    }
  },
  methods : {

    mouseDown() {
      this.isMouseDown = true;
    },
    mouseUp() {
      this.isMouseDown = false;
    },

    deleteAction() {

      if ( this.confirmDeleteCode && this.deleteConfirmationCode !== this.confirmDeleteCode) {
        this.deleteCodeError = this.$t('WrongDeleteConfirmationCode')
        return
      }

      this.$emit('delete');
    },
    cancelButtonAction() {
      if ( this.cancelAction )  {
        this.$emit('cancel')
        return
      }
      this.display = false
    },
    overlayClick($event) {

      if ( this.deselectBlindZoneX && $event.clientX < this.deselectBlindZoneX ) {
        return
      }

      const selection = window.getSelection();
      selection.removeAllRanges();
      if ( this.isMouseDown ) {
        this.isMouseDown = false
        return
      }

      if ( this.persistent ) {
        return
      }
      this.display = false
    }
  },
  beforeMount() {
    this.overlay = this.overlayColor || this.wsACCENT
    if ( this.overlayOpacity ) {
      this.overlay +=  this.overlayOpacity
    } else {
      this.overlay += "bc"
    }
    if ( this.overlayColor ) {
      this.overlay = this.overlayColor
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = JSON.parse(JSON.stringify(this.value))
    }
    if ( this.SM && this.portal ) {
      this.$store.state.MOBILE_FULLSCREEN = true
    }
    this.deleteConfirmationText = this.confirmDeleteText ? this.confirmDeleteText : this.$t('PleaseConfirmDeleteAction')
    setTimeout(()=> {
      if ( this.display && this.$refs.dialogContent ) {
        this.$refs.dialogContent.scrollTop = 0
      }
    },10)
  },
  beforeDestroy() {
    if ( this.portal ) {
      this.$store.state.MOBILE_FULLSCREEN = false
    }
  }
}
</script>

<style scoped>

</style>